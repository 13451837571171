import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import fetch from 'isomorphic-fetch';
import logger from 'utils/logger';

export const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
          logger.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ),
        );
      }
      if (networkError) {
        logger.error(`[Network error]: ${networkError}`);
      }
    }),
    new HttpLink({
      uri: process.env.GRAPHQL_ENDPOINT,
      credentials: 'same-origin'
    })
  ]),    
  fetch,
  cache: new InMemoryCache()
});
