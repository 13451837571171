import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { Provider } from 'react-redux';

import { client } from './src/modules/Apollo/client';
import createStore from './src/modules/Redux/createStore';

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const store = createStore();
  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        {element}
      </Provider>
    </ApolloProvider>
  );
};
