const LOG_LEVEL = 'debug';
const ENV = process.env.NODE_ENV;
console.log(`logger`, ENV);
let logger;

if (ENV === `development`) {
  // import const gemoji from 'gemoji'
  const gemoji = require('gemoji');

  // console.log('gemoji', gemoji.name.cat.emoji)
  // https://raw.githubusercontent.com/omnidan/node-emoji/master/lib/emoji.json
  logger = {
    levels: {
      error: 0,
      warn: 1,
      info: 2,
      verbose: 3,
      debug: 4,
      silly: 5
    },

    logMethods: {
      error: 'error',
      warn: 'warn',
      info: 'info',
      verbose: 'log',
      debug: 'log',
      silly: 'log'
    },

    group (...args) {
      console.groupCollapsed(
        `${gemoji.name.file_folder.emoji}`,
        ...args
      );
    },
    groupEnd (...args) {
      console.groupEnd(...args);
    },

    file (...args) {
      console.groupCollapsed(
        `${gemoji.name.electric_plug.emoji} %c>%c IMPORT %c`,
        'margin-left: 10px; background-color: #ccc; color: #ccc',
        'background-color: #e3e3e3; color: #666; font-weight: 800;',
        'border-top: 4px solid white; border-bottom: 4px solid white; border-left: 6px solid #e3e3e3;',
        ...args
      );
    },
    fileEnd (...args) {
      console.groupEnd(...args);
    },

    component (...args) {
      console.groupCollapsed(
        `${gemoji.name.electric_plug.emoji} %c>%c IMPORT %c`,
        'margin-left: 10px; background-color: #ccc; color: #ccc',
        'background-color: #e3e3e3; color: #666; font-weight: 800;',
        'border-top: 4px solid white; border-bottom: 4px solid white; border-left: 6px solid #e3e3e3;',
        ...args
      );
    },
    componentEnd (...args) {
      console.groupEnd(...args);
    },

    layout (...args) {
      console.groupCollapsed(
        `${gemoji.name.kaaba.emoji} %c>%c LAYOUT %c`,
        'background-color: #111; color: #111',
        'background-color: #333; color: #000; font-weight: 800; color: #ffcc00',
        'border-top: 4px solid white; border-bottom: 4px solid white; border-left: 6px solid #333;',
        ...args
      );
    },
    layoutEnd (...args) {
      console.groupEnd(...args);
    },

    template (...args) {
      console.groupCollapsed(
        `${gemoji.name.package.emoji} %c>%c TEMPLATE >>> %c`,
        'background-color: #14521B; color: #14521B',
        'background-color: #2D870C; color: #000; font-weight: 800; color: #fff',
        'border-top: 4px solid white; border-bottom: 4px solid white; border-left: 6px solid #2D870C;',
        ...args
      );
    },
    templateEnd (...args) {
      console.groupEnd(...args);
    },

    submitGroup (...args) {
      console.groupCollapsed(
        `${gemoji.name.rocket.emoji} %c>%c SUBMIT %c`,
        'background-color: #5186db; color: #5186db',
        'background-color: #bbd3f9; color: #000; font-weight: 800;',
        'border-top: 4px solid white; border-bottom: 4px solid white; border-left: 6px solid #bbd3f9;',
        ...args
      );
    },
    submitEnd (...args) {
      console.groupEnd(
        `${gemoji.name.rocket.emoji} %c>%c SUBMIT %c`,
        'background-color: #5186db; color: #5186db',
        'background-color: #bbd3f9; color: #000; font-weight: 800;',
        'border-top: 4px solid white; border-bottom: 4px solid white; border-left: 6px solid #bbd3f9;',
        ...args
      );
    },

    log (level, ...args) {
      const currentLogLevel = this.levels[LOG_LEVEL];
      if (currentLogLevel >= this.levels[level]) {
        console[this.logMethods[level]](...args);
      }
    },

    error (...args) {
      this.log(
        'error',
        `%c  ERROR  `,
        'background-color: #b21818; color: #fff; font-weight: 800;',
        ...args
      );
    },

    warn (...args) {
      this.log(
        'warn',
        `%c  WARN  `,
        'background-color: #ffb600; color: #383425; font-weight: 800;',
        ...args
      );
    },

    info (...args) {
      this.log(
        'info',
        ` ${gemoji.name.information_source.emoji} `,
        ...args
      );
    },

    verbose (...args) {
      this.log(
        'verbose',
        `${gemoji.name.construction.emoji}`,
        ...args
      );
    },
    load (...args) {
      this.log(
        'verbose',
        `${gemoji.name.package.emoji} %c>%c LOADED %c`,
        'background-color: #b79b73; color: #b79b73',
        'background-color: #efdec6; color: #666; font-weight: 800;',
        'border-top: 4px solid white; border-bottom: 4px solid white; border-left: 6px solid #efdec6;',
        ...args
      );
    },
    spy (...args) {
      this.log(
        'verbose',
        `${gemoji.name.detective.emoji} %c>%c SPY %c`,
        'background-color: #ffb600; color: #ffb600',
        'background-color: #ffca4f; color: #000; font-weight: 800;',
        'border-top: 4px solid white; border-bottom: 4px solid white; border-left: 6px solid #ffca4f;',
        ...args
      );
    },
    user (...args) {
      if (args[0]) {
        if (args[0].email || args[0].name || args[0].sub) {
          this.log(
            'verbose',
            `${gemoji.name.slightly_smiling_face.emoji} %c>%c USER DATA %c`,
            'background-color: #ffb600; color: #ffb600',
            'background-color: #ffca4f; color: #000; font-weight: 800;',
            'border-top: 4px solid white; border-bottom: 4px solid white; border-left: 6px solid #ffca4f;',
            ...args
          );
        }
      } else {
        this.log(
          'verbose',
          `${gemoji.name.lock.emoji} %c>%c NO USER %c`,
          'background-color: #ccc; color: #ccc',
          'background-color: #e3e3e3; color: #666; font-weight: 800;',
          'border-top: 4px solid white; border-bottom: 4px solid white; border-left: 6px solid #e3e3e3;',
          ...args
        );
      }
    },
    currentUser (...args) {
      if (args[0]) {
        if (args[0].email || args[0].name || args[0].sub) {
          this.log(
            'verbose',
            `${gemoji.name.bust_in_silhouette.emoji} %c>%c CURRENT USER %c`,
            'background-color: #ccc; color: #ccc',
            'background-color: #e3e3e3; color: #666; font-weight: 800;',
            'border-top: 4px solid white; border-bottom: 4px solid white; border-left: 6px solid #e3e3e3;',
            true
          );
        }
      } else {
        this.log(
          'verbose',
          `${gemoji.name.lock.emoji} %c>%c CURRENT USER %c`,
          'background-color: #ccc; color: #ccc',
          'background-color: #e3e3e3; color: #666; font-weight: 800;',
          'border-top: 4px solid white; border-bottom: 4px solid white; border-left: 6px solid #e3e3e3;',
          false
        );
      }
    },
    crap (...args) {
      this.log(
        'verbose',
        `${gemoji.name.poop.emoji} %c>%c CRAP %c`,
        'background-color: #844839; color: #844839',
        'background-color: #d8b0a6; color: #666; font-weight: 800;',
        'border-top: 4px solid white; border-bottom: 4px solid white; border-left: 6px solid #d8b0a6;',
        ...args
      );
    },
    kill (...args) {
      this.log(
        'verbose',
        `${gemoji.name.skull.emoji} %c>%c KILL %c`,
        'background-color: #b21818; color: #b21818',
        'background-color: #ff7c7c; color: #000; font-weight: 800;',
        'border-top: 4px solid white; border-bottom: 4px solid white; border-left: 6px solid #ff7c7c;',
        ...args
      );
    },
    chat (...args) {
      this.log(
        'verbose',
        `${gemoji.name.speech_balloon.emoji} %c>%c CHAT %c`,
        'background-color: #ccc; color: #ccc',
        'background-color: #e3e3e3; color: #666; font-weight: 800;',
        'border-top: 4px solid white; border-bottom: 4px solid white; border-left: 6px solid #e3e3e3;',
        ...args
      );
    },
    input (...args) {
      this.log(
        'verbose',
        `${gemoji.name.pencil2.emoji} %c>%c INPUT %c`,
        'background-color: #ccc; color: #ccc',
        'background-color: #e3e3e3; color: #666; font-weight: 800;',
        'border-top: 4px solid white; border-bottom: 4px solid white; border-left: 6px solid #e3e3e3;',
        ...args
      );
    },
    submit (...args) {
      this.log(
        'verbose',
        `${gemoji.name.rocket.emoji} %c>%c SUBMIT %c`,
        'background-color: #5186db; color: #5186db',
        'background-color: #bbd3f9; color: #000; font-weight: 800;',
        'border-top: 4px solid white; border-bottom: 4px solid white; border-left: 6px solid #bbd3f9;',
        ...args
      );
    },
    cc (...args) {
      this.log(
        'verbose',
        `${gemoji.name.credit_card.emoji} %c>%c CC %c`,
        'background-color: #383425; color: #383425',
        'background-color: #ffca4f; color: #000; font-weight: 800;',
        'border-top: 4px solid white; border-bottom: 4px solid white; border-left: 6px solid #ffca4f;',
        ...args
      );
    },
    success (...args) {
      this.log(
        'verbose',
        `${gemoji.name.heavy_check_mark.emoji} %c>%c SUCCESS %c`,
        'background-color: #48a52c; color: #48a52c',
        'background-color: #acff93; color: #000; font-weight: 800;',
        'border-top: 4px solid white; border-bottom: 4px solid white; border-left: 6px solid #acff93;',
        ...args
      );
    },
    fail (...args) {
      this.log(
        'verbose',
        `${gemoji.name.x.emoji} %c>%c FAIL %c`,
        'background-color: #b21818; color: #b21818',
        'background-color: #ff7c7c; color: #000; font-weight: 800;',
        'border-top: 4px solid white; border-bottom: 4px solid white; border-left: 6px solid #ff7c7c;',
        ...args
      );
    },
    query (...args) {
      this.log(
        'verbose',
        `${gemoji.name.bomb.emoji} %c>%c QUERY %c`,
        'background-color: #5186db; color: #5186db',
        'background-color: #bbd3f9; color: #000; font-weight: 800;',
        'border-top: 4px solid white; border-bottom: 4px solid white; border-left: 6px solid #bbd3f9;',
        ...args
      );
    },
    gql (...args) {
      this.log(
        'verbose',
        `${gemoji.name.cherry_blossom.emoji} %c>%c GraphQL %c`,
        'background-color: #8C2069; color: #8C2069',
        'background-color: #E535AB; color: #fff; font-weight: 800;',
        'border-top: 4px solid white; border-bottom: 4px solid white; border-left: 6px solid #E535AB;',
        ...args
      );
    },
    mutation (...args) {
      this.log(
        'verbose',
        `${gemoji.name.bomb.emoji} %c>%c MUTATION %c`,
        'background-color: #ff6a00; color: #ff6a00',
        'background-color: #ffd0ad; color: #000; font-weight: 800;',
        'border-top: 4px solid white; border-bottom: 4px solid white; border-left: 6px solid #ffd0ad;',
        ...args
      );
    },
    request (...args) {
      this.log(
        'verbose',
        `${gemoji.name.mag_right.emoji} %c>%c REQUEST %c`,
        'background-color: #ccc; color: #ccc',
        'background-color: #e3e3e3; color: #666; font-weight: 800;',
        'border-top: 4px solid white; border-bottom: 4px solid white; border-left: 6px solid #e3e3e3;',
        ...args
      );
    },
    results (...args) {
      this.log(
        'verbose',
        `${gemoji.name.mag_right.emoji} %c>%c RESULTS %c`,
        'background-color: #ccc; color: #ccc',
        'background-color: #e3e3e3; color: #666; font-weight: 800;',
        'border-top: 4px solid white; border-bottom: 4px solid white; border-left: 6px solid #e3e3e3;',
        ...args
      );
    },
    response (...args) {
      this.log(
        'verbose',
        `${gemoji.name.boom.emoji} %c>%c RESPONSE %c`,
        'background-color: #c93200; color: #c93200',
        'background-color: #ff6a00; color: #fff; font-weight: 800;',
        'border-top: 4px solid white; border-bottom: 4px solid white; border-left: 6px solid #ff6a00;',
        ...args
      );
    },
    data (...args) {
      this.log(
        'verbose',
        `${gemoji.name.page_facing_up.emoji} %c>%c DATA %c`,
        'background-color: #ccc; color: #ccc',
        'background-color: #e3e3e3; color: #666; font-weight: 800;',
        'border-top: 4px solid white; border-bottom: 4px solid white; border-left: 6px solid #e3e3e3;',
        ...args
      );
    },
    unicorn (...args) {
      this.log(
        'verbose',
        `${gemoji.name.unicorn.emoji} %c>%c UNICORN %c`,
        'background-color: #00eded; color: #00eded',
        'background-color: #a3ffff; color: #000; font-weight: 800;',
        'border-top: 4px solid white; border-bottom: 4px solid white; border-left: 6px solid #a3ffff;',
        ...args
      );
    },
    colors (...args) {
      console.groupCollapsed(
        `%c${gemoji.name.art.emoji} %c DESIGN SYSTEM: COLORS PALETTE %c`,
        'font-size: 18px; padding-top: 8px',
        'padding: 8px 4px; background-color: #000; color: #fff; font-weight: 800;',
        'border-top: 4px solid white; border-bottom: 4px solid white; border-left: 6px solid #333;',
      );
    },
    colorsEnd (...args) {
      console.groupEnd(...args);
    },
    colorGroup (...args) {
      console.groupCollapsed(
        `%c       %c ${args[0].colorName} - ${args[0].color} %c`,
        `background-color: ${args[0].color}; color: ${args[0].color}; border-right: 2px solid white`,
        `color: ${args[0].color}; font-weight: 800;`,
        'border-top: 4px solid white; border-bottom: 4px solid white; border-left: 6px solid #333;',
      );
    },
    colorGroupEnd (...args) {
      console.groupEnd(...args);
    },
    colorBlindGroup (...args) {
      console.groupCollapsed(
        `${gemoji.name.eyeglasses.emoji} %c Color blind simulations %c`,
        'background-color: #999; color: #fff; font-weight: 800;',
        'border-top: 4px solid white; border-bottom: 4px solid white; border-left: 6px solid #333;',
      );
    },
    colorBlindGroupEnd (...args) {
      console.groupEnd(...args);
    },

    debug (...args) {
      this.log(
        'debug',
        `${gemoji.name.space_invader.emoji} %c>%c DEBUG %c`,
        'background-color: #8f66b7; color: #8f66b7',
        'background-color: #e4d0ed; color: #302635; font-weight: 800;',
        'border-top: 4px solid white; border-bottom: 4px solid white; border-left: 6px solid #e4d0ed;',
        ...args
      );
    },

    silly (...args) {
      this.log(
        'debug',
        `${gemoji.name.see_no_evil.emoji} %c>%c SILLY %c`,
        'background-color: #ccc; color: #ccc',
        'background-color: #e3e3e3; color: #666; font-weight: 800;',
        'border-top: 4px solid white; border-bottom: 4px solid white; border-left: 6px solid #e3e3e3;',
        ...args
      );
    }
  };
} else {
  logger = {
    group (...args) { },
    groupEnd (...args) { },
    file (...args) { },
    fileEnd (...args) { },
    component (...args) { },
    componentEnd (...args) { },
    layout (...args) { },
    layoutEnd (...args) { },
    template (...args) { },
    templateEnd (...args) { },
    submitGroup (...args) { },
    submitEnd (...args) { },
    error (...args) { },
    gql (...args) { },
    warn (...args) { },
    info (...args) { },
    verbose (...args) { },
    load (...args) { },
    spy (...args) { },
    user (...args) { },
    currentUser (...args) { },
    crap (...args) { },
    kill (...args) { },
    chat (...args) { },
    input (...args) { },
    submit (...args) { },
    cc (...args) { },
    success (...args) { },
    fail (...args) { },
    query (...args) { },
    mutation (...args) { },
    request (...args) { },
    results (...args) { },
    response (...args) { },
    data (...args) { },
    unicorn (...args) { },
    colors (...args) { },
    colorsEnd (...args) { },
    colorGroup (...args) { },
    colorGroupEnd (...args) { },
    colorBlindGroup (...args) { },
    colorBlindGroupEnd (...args) { },
    debug (...args) { },
    log (...args) { },
    silly (...args) { }
  };
}

// logger.file('Logger Reference');
// logger.group(`Log functions`);
// logger.error(`logger.error(args)`);
// logger.warn(`logger.warn(args)`);
// logger.info(`logger.info(args)`);
// logger.verbose(`logger.verbose(args)`);
// logger.load(`logger.load(args)`);
// logger.spy(`logger.spy(args)`);
// logger.crap(`logger.crap(args)`);
// logger.kill(`logger.kill(args)`);
// logger.chat(`logger.chat(args)`);
// logger.input(`logger.input(args)`);
// logger.submit(`logger.submit(args)`);
// logger.cc(`logger.cc(args)`);
// logger.request(`logger.request(args)`);
// logger.success(`logger.success(args)`);
// logger.fail(`logger.fail(args)`);
// logger.query(`logger.query(args)`);
// logger.mutation(`logger.mutation(args)`);
// logger.response(`logger.response(args)`);
// logger.data(`logger.data(args)`);
// logger.unicorn(`logger.unicorn(args)`);
// logger.debug(`logger.debug(args)`);
// logger.silly(`logger.silly(args)`);
// logger.groupEnd();
// logger.group(`Log Groups`);
// logger.file(`logger.file(label)`);
// logger.fileEnd();
// logger.group(`logger.group(label)`);
// logger.groupEnd();
// logger.submitGroup(`logger.submitGroup(label)`);
// logger.submitEnd();
// logger.groupEnd();
// logger.fileEnd();

// export default logger;
module.exports = logger;
