import { createStore as reduxCreateStore } from 'redux';

const reducer = (state, action) => {
  if (action.type === `INCREMENT`) {
    return Object.assign({}, state, {
      count: state.count + 1
    });
  }
  if (action.type === `TOGGLE_MENU`) {
    return Object.assign({}, state, {
      menu: !state.menu
    });
  }
  if (action.type === `DARK_MENU`) {
    return Object.assign({}, state, {
      darkNav: true
    });
  }
  if (action.type === `LIGHT_MENU`) {
    return Object.assign({}, state, {
      darkNav: false
    });
  }
  if (action.type === `ACTIVE_MENU`) {
    return Object.assign({}, state, {
      menu: true
    });
  }
  if (action.type === `CLOSE_MENU`) {
    return Object.assign({}, state, {
      menu: false
    });
  }
  return state;
};

const initialState = {
  count: 0,
  menu: false,
  darkNav: true
};

const createStore = () => reduxCreateStore(reducer, initialState);
export default createStore;
